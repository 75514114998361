import { FC } from 'react';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import StartFreeNowButton from 'components/StartFreeNowButton/StartFreeNowButton';

const SFXTools: FC = () => {
  return (
    <div className="section-content">
      <div>
        <h2>SFX Tools</h2>
        <h4>Versatile Effects For All Types Of Content</h4>
        <div className="media mobile">
          <video controls>
            <source
              src="https://storage.googleapis.com/persona-music-prod-public/SFX%20DEMO%20VIDEO%20FINAL.mov"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p>
          The Persona library goes way beyond music. Get access to 20,000+
          high-quality sound effects - all fully customizable - and ready to
          bring polish and character to your content.
        </p>
        <StartFreeNowButton />
        <AppSumoRating />
      </div>
      <div className="media desktop">
        <video controls>
          <source
            src="https://storage.googleapis.com/persona-music-prod-public/SFX%20DEMO%20VIDEO%20FINAL.mov"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default SFXTools;
