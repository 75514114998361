import { StyledLandingUseCases } from './UseCasesSection.styled';
import { ReactComponent as Check } from 'assets/images/icons/check-new.svg';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import cases from 'constants/useCases';
import StartFreeNowButton from 'components/StartFreeNowButton/StartFreeNowButton';

const UseCasesSection = () => (
  <StyledLandingUseCases>
    <div className="section-header">
      <h2>Wherever Or Whatever You Create, Persona Has Got You Covered</h2>
      <h4>
        From Video Games to Vimeo, TikTok to Twitch, our music fuels your
        creativity
      </h4>
    </div>
    <div className="section-content">
      <div className="cases">
        {cases.map((c, i) => (
          <div key={c + i}>
            <Check />
            {c}
          </div>
        ))}
      </div>
      <div style={{ textAlign: 'center' }}>
        <StartFreeNowButton />
        <AppSumoRating />
      </div>
    </div>
  </StyledLandingUseCases>
);

export default UseCasesSection;
