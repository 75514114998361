import { StyledLandingSignUp } from './SignUpSection.styled';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import BulletPoint from 'components/BulletPoint/BulletPoint';
import { ReactComponent as Check } from 'assets/images/icons/check-wavy.svg';
import { ReactComponent as Monetize } from 'assets/images/icons/monetize.svg';
import { ReactComponent as Freelance } from 'assets/images/icons/freelance.svg';
import StartFreeNowButton from 'components/StartFreeNowButton/StartFreeNowButton';

const SignUpSection = () => (
  <StyledLandingSignUp>
    <div className="section-content">
      <AppSumoRating />
      <h1>Royalty-Free Music Done Right</h1>
      <BulletPoint icon={Check}>
        <strong>Primetime-Quality: </strong>Heard on Netflix, Marvel, HBO &
        more.
      </BulletPoint>
      <BulletPoint icon={Monetize}>
        <strong>Monetization-Friendly: </strong>Use commercially without
        copyright claims.
      </BulletPoint>
      <BulletPoint icon={Freelance}>
        <strong>Freelance-Compatible: </strong>Create for clients as well as
        yourself.
      </BulletPoint>
      <StartFreeNowButton />
    </div>
  </StyledLandingSignUp>
);

export default SignUpSection;
