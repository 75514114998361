import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SiteWrapper from 'components/Layout/SiteWrapper/SiteWrapper';
import Modals from 'components/Modal/Modals/Modals';
import {
  clearSiteContent,
  getCurrentUser,
} from 'services/state/actions/userActions';
import { getLsToken } from 'utils';
import Routes from 'router/Router';
import { useFacebookPixel } from 'hooks/useFacebookPixel';
import { useQueryState } from 'nuqs';

const App = () => {
  const dispatch = useDispatch();
  const { access } = getLsToken();
  const [group, setGroup] = useQueryState('group');
  const storageGroup = localStorage.getItem('group');

  // Initialize Facebook Pixel
  useFacebookPixel();

  useEffect(() => {
    if (storageGroup) return;

    if (group) {
      localStorage.setItem('group', group);
      return;
    }
    const randomGroup = Math.random() < 0.5 ? 'b' : 'a';
    localStorage.setItem('group', randomGroup);
    setGroup(randomGroup);
  }, [group, storageGroup, setGroup]);

  useEffect(() => {
    if (access) {
      dispatch(getCurrentUser() as any);
    }
    dispatch(clearSiteContent() as any);
  }, [dispatch, access]);

  return (
    <>
      <SiteWrapper>
        <Routes />
      </SiteWrapper>
      <Modals />
    </>
  );
};
export default App;
