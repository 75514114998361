import React from 'react';
import { StyledLargeSwitch } from './LargeSwitch.styled';

interface StyledLargeSwitchProps extends React.HTMLAttributes<HTMLDivElement> {
  $items: number;
  $account?: boolean;
  $selected: number;
  $disabled?: boolean;
}

interface LargeSwitchProps {
  selectedIndex: number;
  items: React.ReactNode[];
  account?: boolean;
  disabled?: boolean;
  handleChange: (index: number) => void;
}

const StyledLargeSwitchTS =
  StyledLargeSwitch as React.FC<StyledLargeSwitchProps>;

const LargeSwitch: React.FC<LargeSwitchProps> = ({
  selectedIndex,
  items,
  account = false,
  disabled = false,
  handleChange,
}) => (
  <StyledLargeSwitchTS
    $items={items.length}
    $account={account}
    $selected={selectedIndex}
    $disabled={disabled}
  >
    <div className="switch">
      <div className="switch-color"></div>
    </div>
    {items.map((item: React.ReactNode, i: number) => (
      <div
        key={i}
        className={`switch-item${selectedIndex === i ? ' active' : ''}`}
        onClick={() => (!disabled ? handleChange(i) : false)}
      >
        {item}
      </div>
    ))}
  </StyledLargeSwitchTS>
);

export default LargeSwitch;
