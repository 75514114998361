import { FC } from 'react';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import StartFreeNowButton from 'components/StartFreeNowButton/StartFreeNowButton';

const StemCreation: FC = () => {
  return (
    <div className="section-content">
      <div>
        <h2>On-Demand Stem Creation</h2>
        <h4>Customize Tracks However You Want</h4>
        <div className="media mobile">
          <video controls>
            <source
              src="https://storage.googleapis.com/persona-music-prod-public/On%20Demand%20Stem%20Creation%20Demo_Edited.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p>
          Just want the guitar, or wish those drums weren't overshadowing the
          melody? With Persona, you can customize a song mix, quickly extracting
          or isolating any instrument to tailor the track to your needs.
        </p>
        <StartFreeNowButton />
        <AppSumoRating />
      </div>
      <div className="media desktop">
        <video controls>
          <source
            src="https://storage.googleapis.com/persona-music-prod-public/On%20Demand%20Stem%20Creation%20Demo_Edited.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default StemCreation;
