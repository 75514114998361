import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';
import { useQueryState } from 'nuqs';
import { useNavigate } from 'react-router-dom';

const StartFreeNowButton = () => {
  const [group] = useQueryState('group');
  const storageGroup = group || localStorage.getItem('group');
  const navigate = useNavigate();

  return (
    <ActionArrowButton
      $primary
      onClick={storageGroup === 'b' ? () => navigate('/pricing') : undefined}
    >
      {storageGroup === 'b' ? 'Try For Free' : 'Start Free Now'}
    </ActionArrowButton>
  );
};

export default StartFreeNowButton;
